<template>
  <div class="main">
    <div class="login-body">
      <Form ref='loginForm' :model="formData" :rules="ruleInline">
        <h3>IPAAS 管理中心</h3>
        <h5>Ipass Management Center</h5>
        <Alert type="error" v-show="message !== ''">{{ message }}</Alert>
        <FormItem label="" class="loginFormItem" prop="account" label-position="top">
          <Input v-model="formData.account" size="large" @on-enter="login" @on-change="message = ''" placeholder="登录账号" />
        </FormItem>
        <FormItem label="" class="loginFormItem" prop="password" label-position="top">
          <Input v-model="formData.password" type="password" size="large" @on-enter="login" @on-change="message = ''" placeholder="登录密码" />
        </FormItem>
        <FormItem>
          <Button type="primary" class="submit" size="large" @click="login">登 录</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import {post} from "@/api/http";
import {generateRoutes} from "@/utils/generateRoutes.js"
let md5 = require('js-md5');

export default {
  name: "Login",
  data() {
    return {
      message: '',
      formData: {
        account: '',
        password: ''
      },
      tree: [],
      ruleInline: {
        account: [
          { required: true, message: 'Please fill in the user account', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please fill in the password.', trigger: 'blur' },
          { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
        ]
      }
    }
  },
  methods:{
    login(){
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let formData = this.$_.cloneDeep(this.formData)
          formData.password = md5(formData.password)
          post("/api/v1/loginByAccount", formData)
              .then((resp)=>{
                if (resp.code === 0) {
                  this.message = "";
                  localStorage.setItem("token", resp.data.token)
                  generateRoutes()
                } else {
                  this.message = resp.msg;
                }
              })
              .catch(e => {
                console.log(e)
              })
        }
      })
    }
  }
}
</script>

<style scoped>
.main {
  position: relative;
  /*background-color: #17233d;*/
  background-size: cover;
  background-image: url("../assets/word.gif");
  min-height: 100vh;
}

.login-body {
  color: #515a6e;
  background-color: #f8f8f9;
  width: 500px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
  border-radius: 5px;
  padding: 20px 40px;
}

.login-body h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.login-body h5 {
  border-bottom: 1px solid #dcdee2;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding-bottom: 10px;
}
.loginFormItem {
  margin-bottom: 35px;
}
.submit {
  width: 100%;
  margin-top: 20px;
}
</style>
